import React from 'react'
import './workCard.styles.scss'
import Line from '../Line/line.component'
import EllipsisText from 'react-ellipsis-text'

function WorkCard({project:{name,description,slug,backgroundColor}}) {
    return (
        <a href={`/work/${slug}`} className="work__card" style={{backgroundColor}}>
        <div className="card__heading">
            <div className="card__title">
                {name}
            </div>
            <Line  width="110px" />
        </div>

        <div className="card__description">
            <EllipsisText text={description} length={"96"} />

             
        </div>

        <div className="card__footer">
           
            <div className="card__view">
                View Work
            </div>
        </div>

    </a>
    )
}

export default WorkCard
