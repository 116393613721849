import React from 'react'
import About from '../../sections/About/about.component'
import Contact from '../../sections/Contact/contact.component'
import Landing from '../../sections/Landing/landing.component'
import Skill from '../../sections/Skills/skills.component'
import Work from '../../sections/Work/work.component'
import './home.styles.scss'
import { motion } from 'framer-motion'
import { pageAnimation } from '../../Animation/animation'

function Home() {
    return (
        <motion.div className="home-container" variants={pageAnimation} initial="hidden" animate="show"  >
            
            <Landing />
            <About />
            <Skill />
            <Work />
            <Contact />

        </motion.div>
    )
}

export default Home
