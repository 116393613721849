import React from 'react'
import LOGO from '../Logo/logo.component'
import NavButton from '../NavButton/navButton.component'
import {Link} from 'react-router-dom'
import './header.styles.scss'

function Header() {
    return (
        <div className="header-container">
            <Link to="/">
                <LOGO />
            </Link>
            <NavButton  />
        </div>
    )
}

export default Header
