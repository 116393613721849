import React from 'react';
import './App.scss';
import Footer from './components/Footer/footer.component';
import Header from './components/Header/header.component';
import Social from './components/SocialMedia/social.component';
import Home from './pages/Home/home.page';
import {Switch,Route, useLocation} from 'react-router-dom'
import Project from './pages/Project/project.page';
import Menu from './components/Menu/menu.component';
import {AnimatePresence} from "framer-motion"


function App() {

  const location = useLocation();
  return (
    <>
      
        <Menu/>          
        <Header/>   
  
        <Social/>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname} >
            <Route exact path='/' component={Home} />
            <Route exact path='/work/:project' component={Project} />
          </Switch>
        </AnimatePresence>
        <Footer />
    </>
  );
}

export default App;
