import axios from "axios"
import { projectTypes } from "../types/projects.types"


export const getProjectsAsync = () => async dispatch => {

    try {
        dispatch({type:projectTypes.GET_PROJECTS_START})
        
        const {data} = await axios.get("/api/project")
        
        dispatch({type:projectTypes.GET_PROJECTS_SUCCESS,payload:data})
        
    } catch (err) {
        
        dispatch({type:projectTypes.GET_PROJECTS_FAIL,payload:err})
    }


}
export const getProjectAsync = (slug) => async dispatch => {

    try {
        dispatch({type:projectTypes.GET_PROJECT_START})
        
        const {data} = await axios.get(`/api/project/${slug}`)
        dispatch({type:projectTypes.GET_PROJECT_SUCCESS,payload:data})
        
    } catch (err) {
        
        dispatch({type:projectTypes.GET_PROJECT_FAIL,payload:err})
    }


}





