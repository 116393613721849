import React from 'react'
import './project-detail.styles.scss'
import Line from '../../components/Line/line.component'
import { wrap } from 'popmotion'
import {ReactComponent as Arrow} from '../../assets/Icon awesome-arrow-down.svg'
import ProjectItem from '../../components/project-item/project-item.component'
import { useState } from 'react'

function ProjectDetail({backgroundColor,gallery}) {


  
    const [index,setIndex] =useState(0)
    const galleryIndex = wrap(0,gallery.length,index)


    return (
        <div className="project-detail-container">
            <ProjectItem item={gallery[galleryIndex]} backgroundColor={backgroundColor} />
            {
               gallery.length !== 1 &&
            <div className="project-detail__nav">
                    <div className="project-detail__nav--1" onClick={()=>setIndex(index+1)}>
                        <Arrow/>
                    </div>
                    <div className="project-detail__nav--2" onClick={()=>setIndex(index-1)}>
                        <Arrow/>
                    </div>
            </div>
           }

        </div>
    )
}

export default ProjectDetail
