import React from 'react'
import './loader.styles.scss'

function Loader() {
    return (
        <div className="loader-overlay">
            <div className="loader">

            </div>
        </div>
    )
}

export default Loader
