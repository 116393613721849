import axios from "axios";
import { appTypes } from "../types/app.types";

export const toggleMenu = () => ({
    type:appTypes.TOGGLE_MENU
})

export const contactAsync = (data) => async dispatch => {
    
    try {
        dispatch({type:appTypes.SEND_START})
        
        axios.post('/api/project/contact',data)
        
        dispatch({type:appTypes.SEND_SUCCESS})
        
        
        
    } catch (error) {
        dispatch({type:appTypes.SEND_FAIL,payload:error})
        
    }
 
 
}