import { projectTypes } from "../types/projects.types";


export const projectsReducer = (state={loading:true,projects:[]},action) => {

    switch (action.type) {
        case projectTypes.GET_PROJECTS_START:
            
            return {
                ...state,
                loading:true,
                err:undefined,
                projects:[],
            }
        case projectTypes.GET_PROJECTS_SUCCESS:
            
            return {
                ...state,
                loading:false,
                projects:action.payload,
                err:undefined
            }            
            
            case projectTypes.GET_PROJECTS_FAIL:
                return {
                ...state,
                loading:false,
                projects:[],
                err:action.payload
            }            
            
    
        default:
            return state
    }


}



export const projectReducer = (state={loading:true,project:null},action) => {

    switch (action.type) {
        case projectTypes.GET_PROJECT_START:
            
            return {
                ...state,
                loading:true,
                err:undefined,
                project:null,
            }
        case projectTypes.GET_PROJECT_SUCCESS:
            
            return {
                ...state,
                loading:false,
                project:action.payload,
                err:undefined
            }            
            
            case projectTypes.GET_PROJECT_FAIL:
                return {
                ...state,
                loading:false,
                project:null,
                err:action.payload
            }            
            
    
        default:
            return state
    }


}

