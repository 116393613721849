import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { toggleMenu } from '../../redux/actions/app.action';
import './navButton.styles.scss'

function NavButton() {

    const dispatch = useDispatch();
    const {hidden} = useSelector(state=>state.appReducer)

    return (
        <div className={`nav-button-container ${!hidden ? 'nav-button-container-active':''}`} onClick={()=>dispatch(toggleMenu())}>
            <div className="nav-line nav-line-1 nav-line-inactive">

            </div>
            <div className="nav-line nav-line-2 nav-line-inactive">

            </div>
            
        </div>
    )
}

export default NavButton
