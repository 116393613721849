import {combineReducers} from 'redux'
import appReducer from './reducers/app.reducer'
import {projectsReducer,projectReducer} from './reducers/projects.reducer'

const rootReducer = combineReducers({
    appReducer,
    projectsReducer,
    projectReducer
})

export default rootReducer