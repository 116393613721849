import { appTypes } from "../types/app.types"

 

const appReducer =(state={hidden:true,sent:false,loading:true},action) =>{
    switch (action.type) {
        case appTypes.TOGGLE_MENU:
            return {
                ...state,
                hidden:!state.hidden
            }
            
        case appTypes.SEND_START:
            return {
                ...state,
                loading:true,
                sent:false
                
            }
            case appTypes.SEND_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    sent:true
                }
            case appTypes.SEND_FAIL:
                return {
                    ...state,
                    loading:false,
                    sent:true,
                    err:action.payload

            }
            
    
        default:
            return state
    }
}

export default appReducer
