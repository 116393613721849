import {createStore,applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import {composeWithDevTools} from 'redux-devtools-extension'

const middleWares = [thunk]


const initialState={}


const store = createStore(rootReducer,initialState,composeWithDevTools(applyMiddleware(...middleWares)))

export default store