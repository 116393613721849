import React from 'react'
import './line.styles.scss'

function Line({width}) {
    return (
        <div className="line" style={{width}}>
            
        </div>
    )
}

export default Line
