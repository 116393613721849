import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleMenu } from '../../redux/actions/app.action'
import './menu-item.styles.scss'

function MenuItem({children}) {
    


    const dispatch = useDispatch()

    const handleClick = () => {
        
        dispatch(toggleMenu())
    }

    return (
        <div className="menu-item" onClick={handleClick}>
            {children}
        </div>
    )
}

export default MenuItem
