import React from 'react'
import './project-desc.styles.scss'

function ProjectDesc({description,techs,services,links}) {
    return (
        <div className="project-about-container" id="project-about-container">
            
            <div className="project-group project-group--description">
                <div className="project-group-title">
                    description
                </div>
            
                <div className="project-group-info">
                    {description}
                </div>

            </div>

            <div className="project-group">
                <div className="project-group-title">
                    Used Technologies
                </div>
            
                <div className="project-group-info">
                    {techs.map(el => `${el}, ` )}
                </div>

            </div>
            <div className="project-group">
                <div className="project-group-title">
                    Services
                </div>
            
                <div className="project-group-info">
                    {services.map(el => <div>{el}</div> )}

                </div>

            </div>
            <div className="project-group">
                <div className="project-group-title">
                    lINKS
                </div>
            
                <div className="project-group-info">
                    {links.map(el => <div><a target='_blank' href={el}>{el}</a></div>  )}
                </div>

            </div>

        </div>
    )
}

export default ProjectDesc
