import React from "react";
import "./social.styles.scss";
import { ReactComponent as Up } from "../../assets/Upwork.svg";
import { ReactComponent as Linked } from "../../assets/linkedin.svg";
import { ReactComponent as TWT } from "../../assets/Twt.svg";

function Social() {
  return (
    <div className="social-container">
      <a target="_blank" href="https://twitter.com/ouafi_omar">
        <TWT />
      </a>
      <a
        target="_blank"
        href="https://www.linkedin.com/in/omar-ouafi-a455911a9"
      >
        <Linked />
      </a>
      <a
        target="_blank"
        href="https://www.upwork.com/freelancers/~01ba20b73785b81608"
      >
        <Up style={{ width: "3rem" }} />
      </a>
    </div>
  );
}

export default Social;
