import React from 'react'
import {motion} from 'framer-motion'

function DetailWave() {
    return (
   
    <svg className="detail-wave" xmlns="http://www.w3.org/2000/svg" width="2314.087" height="1131.321" viewBox="0 0 2314.087 1131.321">
        <motion.path  initial={{pathLength:0,pathOffset:1}} animate={{pathLength:1,pathOffset:0}} transition={{duration:2.5,ease:"easeInOut"}} id="Path_39" data-name="Path 39" d="M-9042.5-355.471S-8294.316-23.7-7867.752-192.97-7583.662,157.015-7378,322.055s249.955,88.114,486.935,67.8,143.889,385.408,143.889,385.408" transform="translate(9042.701 355.928)" fill="none" stroke="#707070" stroke-width="1"/>
    </svg>
      

      

    )
}

export default DetailWave
