import React from 'react'
import './landing.styles.scss'

import Button from '../../components/Button/button.component'
import DetailWave from '../../components/detail-wave/detail-wave.component'

function ProjectLanding({name,backgroundColor}) {
    return (
        <div className="project-landing" style={{backgroundColor}}>
            <DetailWave/>
            <h1 className="project-title">{name}</h1>
            <a href="#project-about-container"> <Button>More Info</Button></a>
        </div>
    )
}

export default ProjectLanding
