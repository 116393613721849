import React from 'react'
import Line from '../Line/line.component'
import './logo.styles.scss'

function LOGO() {
    return (
        <div className="logo">
            <div className="logo-name">Omar</div>
            
        </div>
    )
}

export default LOGO
