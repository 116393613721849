import React from "react";
import Line from "../../components/Line/line.component";
import "./skills.styles.scss";
function Skill() {
  return (
    <div className="skills" id="skills">
      <div className="skills-title">
        <div className="section-title">Skills</div>
        <Line width="40px" />
      </div>

      <div className="skills-cards">
        <div className="card">
          <div className="card__title">Front-End</div>

          <ul className="card__items">
            <li className="card__item">HTML/CSS</li>
            <li className="card__item">Javascript</li>
            <li className="card__item">Typescript</li>
            <li className="card__item">React.JS</li>
            <li className="card__item">Angular</li>
          </ul>
        </div>
        <div className="card">
          <div className="card__title">Back-end</div>

          <ul className="card__items">
            <li className="card__item">Node.JS</li>
            <li className="card__item">Symfony</li>
            <li className="card__item">JEE</li>
            <li className="card__item">.NET</li>
            <li className="card__item">UML</li>
            <li className="card__item">NGINX</li>
          </ul>
        </div>
        <div className="card">
          <div className="card__title">Databases</div>

          <ul className="card__items">
            <li className="card__item">MySQL</li>
            <li className="card__item">PostgreSQL</li>
            <li className="card__item">Firebase</li>
            <li className="card__item">MongoDB</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Skill;
