import React from "react";
import Button from "../../components/Button/button.component";
import "./landing.styles.scss";
import { motion } from "framer-motion";
import { fade, pageAnimation, textAnimation } from "../../Animation/animation";
import LandingWave from "../../components/landing-wave/landing-wave.component";

function Landing() {
  return (
    <motion.div
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      className="landing-section"
      id="home"
    >
      <LandingWave />

      <div className="landing-text">
        <div className="sub-heading hide">
          <motion.div variants={textAnimation} initial="hidden" animate="show">
            HI THERE!
          </motion.div>
        </div>

        <h1 className="main-heading">
          <div className="hide">
            {" "}
            <motion.div
              variants={textAnimation}
              initial="hidden"
              animate="show"
            >
              {" "}
              My name is <span>Omar Ouafi</span>
            </motion.div>
          </div>

          <div className="hide">
            <motion.div
              variants={textAnimation}
              initial="hidden"
              animate="show"
            >
              {" "}
              Full-stack developer
            </motion.div>
          </div>
        </h1>

        <motion.div variants={fade} initial="hidden" animate="show">
          <a href="#about" style={{ color: "white" }}>
            <Button className="landing-btn">More Info </Button>
          </a>
        </motion.div>
      </div>

      <div className="scroll-container">
        <div className="scroll-text">Scroll</div>
        <div className="scroll-line"></div>
      </div>
    </motion.div>
  );
}

export default Landing;
