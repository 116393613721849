import React from 'react'
import MenuItem from '../menu-item/menu-item.component'
import './menu-list.styles.scss'

function MenuList() {
    return (
        <div>
            <a href="/#home"> <MenuItem>Home</MenuItem></a>
            <a href="/#about"> <MenuItem>About</MenuItem></a>
            <a href="/#skills"> <MenuItem>Skills</MenuItem></a>
            <a href="/#work"> <MenuItem>Work</MenuItem></a>
            <a href="/#contact"> <MenuItem>Contact</MenuItem></a>
        </div>
    )
}

export default MenuList
