import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WorkCard from '../../components/Card/workCard.component'
import Line from '../../components/Line/line.component'
import { getProjectsAsync } from '../../redux/actions/project.action'
import './work.styles.scss'
import Loader from '../../components/Loader/loader.component'

function Work() {


    const dispatch = useDispatch()
    const {projects,loading} = useSelector(state=>state.projectsReducer)

    useEffect(() => {
        dispatch(getProjectsAsync())
    }, [dispatch])


    return (
        loading ? <Loader/> : 
        <div className="work" id="work">
            <div className="work-title">
                <div className="section-title">
                    Work
                </div>
                <Line width="40px"/>
            </div>

            <div className="work-container">
                
                {
                    projects.map(el=><WorkCard project={el} />)
                }
                

            </div>

        </div>
    )
}

export default Work
