





export const pageAnimation = {
    hidden:{
        opacity:0
    },
    show:{
        opacity:1,
        transition:{
            staggerChildren:1,
            duration:.5,
            when:"beforeChildren",
        },
    },
    exit:{
        opacity:0,
        transition:{
            duration:.5
        },
        
    }
}

export const textAnimation = {
    hidden:{
        y:100
    },
    show:{
        y:0,
        transition:{
            duration:.7,
            ease:"easeOut"
        }
    }
}
export const fade = {
    hidden:{
        opacity: 0
    },
    show:{
        opacity: 1,
        transition:{
            duration:.3,
            ease:"easeOut"
        }
    }
}


export const animateMenu = {
    hidden:{
        y:-100
    },
    show:{
        y:0,
        transition:{
            duration:.3,
            ease:"easeOut"
        }
    },
    exit:{
        y:-100,
        opacity: 1,
        transition:{
            duration:.3,
            ease:"easeOut"
        }
    }
}