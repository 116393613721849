import React from 'react'
import ProjectDesc from '../../sections/project-desc/project-desc.component'
import ProjectDetail from '../../sections/project-detail/project-detail.section'
import ProjectLanding from '../../sections/Project-Landing/landing.component'
import './project.styles.scss'
import {motion} from 'framer-motion'
import { pageAnimation } from '../../Animation/animation'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getProjectAsync } from '../../redux/actions/project.action'
import Loader from '../../components/Loader/loader.component'


function Project({match}) {

    const dispatch = useDispatch()
    const {project,loading} = useSelector(state=>state.projectReducer)

    useEffect(() => {
        dispatch(getProjectAsync(match.params.project))
        
    }, [dispatch,match.params.project])
    console.log(loading);


    return (
        loading ? <Loader/> :
        <motion.div className="project" variants={pageAnimation} initial="hidden" animate="show"  >
            <ProjectLanding {...project} />
            <ProjectDesc {...project} />
            <ProjectDetail {...project} />
        </motion.div>
    )
}

export default Project
