import React from 'react'
import Line from '../Line/line.component'
import './project-item.styles.scss'
import {motion,AnimatePresence} from 'framer-motion'
import { pageAnimation } from '../../Animation/animation'

function ProjectItem({backgroundColor,item}) {
    return (
        <>
            <AnimatePresence initial={false} exitBeforeEnter>
                <div className="project-detail"  >
                    
                    <motion.div className="project-detail__description-container" key={item._id} variants={pageAnimation} initial="hidden" animate="show" exit="exit">
                        <div className="project-detail__heading hide">
                            <motion.h2  >{item.title}</motion.h2>
                            <Line width="120px" />
                        </div>
                        <p className="project-detail__description">
                            {item.description}
                        </p>     

                    

                    </motion.div>
                    <div className="project-detail__image-container" style={{backgroundImage:`linear-gradient(to left,${backgroundColor} 0% ,${backgroundColor} 70%,rgba(255, 255, 255, 0) 30%)`}} >
                        <motion.div key={item._id} initial={{opacity:0}} animate={{opacity:1,transition:{duration:.7,staggerChildren:.25}}} exit={{opacity:0}} className="project-detail__image">
                            <img src={item.image} alt="" />
                        </motion.div>
                    </div>

                </div>
            </AnimatePresence>
        </>
    )
}

export default ProjectItem
