import React from 'react'
import './button.styles.scss'
import {ReactComponent as Arrow} from '../../assets/Icon awesome-arrow-down.svg'

function Button({arrow,children}) {
    return (
        <button className={`btn ${arrow ? 'btn-down-arrow' : ''} `}>
            <div className="btn-text">
                {children}
            </div>
            <div className="btn-arrow">
                <Arrow />
            </div>
            
        </button>
    )
}

export default Button
