 
export const appTypes = {
    TOGGLE_MENU : "TOGGLE_MENU",
    
    SEND_START : "SEND_START",
    SEND_SUCCESS : "SEND_SUCCESS",
    SEND_FAIL : "SEND_FAIL",


}
