import React from 'react'
import './form-input.styles.scss'


function FormInput({label,...props}) {
    return (
        <div className="contact__field">
            <input className="contact__input" {...props} />
            <label className={`contact__label ${props.value.length > 0 ? "shrink":"" }`}  >{label}</label>
        </div>
    )
}

export default FormInput
