import React from "react";
import { useSelector } from "react-redux";
import MenuList from "../menu-list/menu-list.component";
import "./menu.styles.scss";

function Menu() {
  const { hidden } = useSelector((state) => state.appReducer);

  return (
    <div className={`menu ${!hidden ? "menu-active" : ""} `}>
      <div className="menu-info">
        <div className="menu-info-title">contact &nbsp; details</div>
        <div className="menu-info-el">
          <a href="mailto:omarouafi2@gmail.com">omarouafi12@gmail.com</a>
        </div>
        <div className="menu-info-el">
          <a href="tel:+33671274336">+33 671274336</a>
        </div>
        <div className="menu-info-el menu-info-el--copy">
          &copy; {new Date(Date.now()).getFullYear()} Omar Ouafi
        </div>
      </div>

      <MenuList />
    </div>
  );
}

export default Menu;
