import React from "react";
import "./footer.styles.scss";
import { ReactComponent as Up } from "../../assets/Upwork.svg";
import { ReactComponent as Linked } from "../../assets/linkedin.svg";
import { ReactComponent as TWT } from "../../assets/Twt.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-social">
        <a target="_blank" href="https://twitter.com/ouafi_omar">
          <TWT />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/in/omar-ouafi-a455911a9"
        >
          <Linked />
        </a>
        <a
          target="_blank"
          href="https://www.upwork.com/freelancers/~01ba20b73785b81608"
        >
          <Up style={{ width: "3rem" }} />
        </a>
      </div>
      <div className="copy">
        &copy; {new Date(Date.now()).getFullYear()} Omar Ouafi
      </div>
    </div>
  );
}

export default Footer;
