import React from "react";
import AboutWave from "../../components/about-wave/about-wave.component";
import Line from "../../components/Line/line.component";
import "./about.styles.scss";

function About() {
  return (
    <div className="about" id="about">
      <div className="about-description">
        <div className="sub-heading">ABOUT</div>
        <div className="about-title">
          <div className="section-title">who am i ?</div>
          <Line width="40px" />
        </div>

        <div className="about-text">
          My name is <span>Omar Ouafi</span>{" "}
          {new Date(Date.now()).getFullYear() - 2001} years old I'm a software
          developer based in France. I'm passionate about problem-solving and
          constantly learning new things.{" "}
          <p style={{ marginTop: "1rem" }}>
            With experience in building robust applications and creating
            intuitive interfaces, I am dedicated to delivering high-quality work
            that meets the needs of my clients and users.
          </p>
        </div>
      </div>
      <div className="about-abilities">
        <div className="about-abilities--1">
          <div className="ability-title">DESIGN</div>
          <div className="ability-item">Web design</div>
          <div className="ability-item">Prototyping</div>
          <div className="ability-item">Branding</div>
        </div>
        <div className="about-abilities--1">
          <div className="ability-title">Development</div>
          <div className="ability-item">Web</div>
          <div className="ability-item">Mobile</div>
          <div className="ability-item">Desktop</div>
        </div>
      </div>

      <AboutWave />
    </div>
  );
}

export default About;
