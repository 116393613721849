import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import FormInput from '../../components/FormInput/form-input.component'
import Line from '../../components/Line/line.component'
import { contactAsync } from '../../redux/actions/app.action'
import './contact.styles.scss'

function Contact() {

    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [message,setMessage] = useState("")

    const dispatch = useDispatch()
    const handleSubmit = (e) =>{

        e.preventDefault()
        dispatch(contactAsync({name,email,message}))
        alert("thank you for contacting me")
    }

    return (
        <div className="contact" id="contact">
            <div className="sub-heading">
                Contact 
            </div>
            <div className="contact-title">
                <div className="section-title">
                    GET in touch
                </div>
                <Line width='40px'/>
            </div>

            <div className="contact-container">
                <form action="" className="contact__form" onSubmit={handleSubmit}>

                    
                    <FormInput label="Name" value={name} type="text" required name="Name" onChange={e=>setName(e.target.value)} />
                    <FormInput label="Email" type="email" value={email} required name="Email" onChange={e=>setEmail(e.target.value)} />
                    
                    <div className="contact__message-container">
                        <textarea  className="contact__message" value={message} onChange={e=>setMessage(e.target.value)} name="" id="" rows="6"></textarea>
                        <label className={`contact__label ${message.length>0?"shrink":""} `}>Message</label>
                    </div>

                    <button className="contact__send">Send</button>
                </form>
            </div>
        

        </div>
    )
}

export default Contact
