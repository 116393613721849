import React from 'react'
import {motion} from 'framer-motion'

function AboutWave() {
    return (
   
    <svg className="about-wave" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2223 418.236">
         <motion.path  initial={{pathLength:0,pathOffset:1}} animate={{pathLength:1,pathOffset:0}} transition={{duration:2.5,ease:"easeInOut"}} id="Path_44" data-name="Path 44" d="M-9614.732,3370.147s-326.035-212.921-565.572-96.48-156.845,295.874-14.885,359.228,320.845-7.914,276.58-155.849-399.437-249.705-624.327-156.8-168.841,184.643-499.035,196.287-612.148-6.654-795.128-196.287" transform="translate(11837.459 -3238.339)" fill="none" stroke="#a58861" stroke-width="1"/>
    </svg>

      

    )
}

export default AboutWave
